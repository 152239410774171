import { useEffect, useRef, useState } from "react";
import TopBannerForOtherPages from "../components/TopBannerForOtherPages";
import { getLanguage, countries } from "../constants/GeneralConstants";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getContactPageData,
  submitContactFormDetails,
} from "../services/ApiService";
import "../css/pagescss/Contact.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setColor } from "../store/reducers/ColorReducer";
import ContactAccrordion from "../components/ContactAccrordion";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import { SlantImg } from "../components/CommonComponents";
import ButtonSlant from "../components/ButtonSlant";

const continentOrder = ["Europe", "Asia", "North America", "South America"];

const Contact = () => {
  const { lang } = useParams();
  const formObj = {
    fname: "",
    lname: "",
    email: "",
    number: "",
    location: "",
    natureOfEnquiry: "",
    hearAboutUs: "",
    addAMessage: "",
    terms: "",
    captchaToken: "",
  };

  const [contactPageData, setContactPageData] = useState(null);
  const [formData, setFormData] = useState(formObj);
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  // const [countryList, setCountryList] = useState(
  //   countries.sort((a, b) => {
  //     let indexA = continentOrder.indexOf(a.continent);
  //     let indexB = continentOrder.indexOf(b.continent);

  //     if (indexA === -1) indexA = continentOrder.length;
  //     if (indexB === -1) indexB = continentOrder.length;

  //     if (indexA !== indexB) {
  //       return indexA - indexB;
  //     }
  //     return a.name.localeCompare(b.name);
  //   })
  // );
  const [countryList, setCountryList] = useState(
    countries.sort((objA, objB) => objA.name.localeCompare(objB.name))
  );
  const [initialHearAboutUsList, setInitialHearAboutUsList] = useState([]);
  const [hearAboutList, setHearAboutList] = useState([]);
  const [initialNatureOfEnquiryList, setInitialNatureOfEnquiryList] = useState(
    []
  );
  const [natureOfEnquiryList, setNatureOfEnquiryList] = useState([]);
  const [showNatureOfEnquiryDropdown, setShowNatureOfEnquiryDropdown] =
    useState(false);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [showHearAboutUsDropdown, setShowHearAboutUsDropdown] = useState(false);
  const [showCheckTick, setShowcheckTick] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loader, setLoader] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [recaptchaId, setRecaptchaId] = useState(null);

  const natureOfEnquiryInputRef = useRef();
  const hearAboutUsInputRef = useRef();
  const locationInputRef = useRef();
  const lnameRef = useRef(null);
  const fnameRef = useRef(null);
  const mailRef = useRef(null);
  const numberRef = useRef(null);
  const messageRef = useRef(null);
  const locationMainRef = useRef(null);
  const natureOfEnquiryMainRef = useRef(null);
  const hearAboutUsMainRef = useState(null);
  const termsRef = useRef(null);
  const recaptchaRef = useRef(null);
  const [focusStates, setFocusStates] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [animatedHeaderText, setAnimatedHeaderText] = useState([]);
  const [index, setIndex] = useState(0);
  const [captchaLanguage, setCaptchaLanguage] = useState(lang);
  const [captchaKey, setCaptchaKey] = useState(0);
  const [locations, setLocations] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#2AD9FF",
        secondary: "#FFF647",
        tertiary: "#004780",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    callContactPageData(getLanguage());
    const languageMap = {
      "fr-ca": "fr-CA",
      ja: "ja",
      ko: "ko",
      "zh-chs": "zh-CN",
      "zh-cht": "zh-TW",
    };
    const language = languageMap[lang] || "en";
    setCaptchaLanguage(language);

    setCaptchaKey((prev) => prev + 1);
  }, [lang]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const numberRegularExpression = /^[0-9+\-()]*$/;

    if (name === "number" && !numberRegularExpression.test(value)) return;
    if (name === "addAMessage" && (value.includes("<") || value.includes(">")))
      return;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //on blur validation
  const handleBlur = (e, index) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => {
      const validationErrors = validateForm({ ...formData, [name]: value });
      return {
        ...prevErrors,
        [name]: validationErrors[name] || undefined,
      };
    });
    const newFocusStates = [...focusStates];
    newFocusStates[index] = false;
    setFocusStates(newFocusStates);
  };

  const handleFocus = (index) => {
    const newFocusStates = [...focusStates];
    newFocusStates[index] = true;
    setFocusStates(newFocusStates);
  };
  // seperate condition for checkbox
  const handleTermsChange = (e) => {
    const { checked, name } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
    setShowcheckTick(checked);

    setErrors((prevErrors) => ({
      ...prevErrors,
      terms: checked
        ? ""
        : contactForm_validationMessages_privacyPolicyValidationMessage,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const validateNumber = (number) => {
    const numberRegex = /^(\+|00|0)?[0-9][0-9 \-\(\)\.]{6,16}$/;
    return numberRegex.test(number);
  };

  const validateName = (name) => {
    const nameRegex = /^[\p{L}\p{M}\s'-]+$/u;
    return nameRegex.test(name);
  };

  const validateForm = (data) => {
    let formErrors = {};
    if (!data.fname.trim()) {
      formErrors.fname = "Please enter a first name";
    } else if (data?.fname?.replace(/\s+/g, "").length < 3) {
      formErrors.fname = "first name must be minimum 3 letters";
    } else if (!validateName(data.fname.trim())) {
      formErrors.fname2 =
        "Name should not contain specail characters and numbers";
    }

    if (!data.lname.trim()) {
      formErrors.lname = "Please enter a last name";
    } else if (data?.lname?.replace(/\s+/g, "").length < 3) {
      formErrors.lname = "last name must be minimum 3 letters";
    } else if (!validateName(data.lname.trim())) {
      formErrors.lname2 =
        "Name should not contain specail characters and numbers";
    }

    // if (data.number !== "" && !validateNumber(formData.number)) {
    //   formErrors.number = "Please enter valid mobile number";
    // }

    if (!data.email.trim()) {
      formErrors.email = "Please enter a email";
    } else if (!validateEmail(data.email)) {
      formErrors.email = "Please enter a valid email";
    }
    if (!data.location) {
      formErrors.location = "Please select a location";
    }
    // if (!data.hearAboutUs) {
    //   formErrors.hearAboutUs = "Please select one option";
    // }
    if (!data.natureOfEnquiry) {
      formErrors.natureOfEnquiry = "Please select one option";
    }
    if (!data.addAMessage.trim()) {
      formErrors.addAMessage = "Please add a message";
    } else if (data.addAMessage.trim().length < 20) {
      formErrors.addAMessage = "Message must be 20 charecters lenth";
    }
    if (!data.terms) {
      formErrors.terms =
        contactForm_validationMessages_privacyPolicyValidationMessage;
    }

    return formErrors;
  };

  const handleLocationSearch = (e) => {
    const filtered = countries.filter((c) =>
      c?.name?.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setCountryList([...filtered]);
  };

  const handleNatureOfEnquirySearch = (e) => {
    const filtered = initialNatureOfEnquiryList?.filter((c) =>
      c?.value?.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setNatureOfEnquiryList([...filtered]);
  };

  const handleHearAboutSearch = (e) => {
    let { value } = e.target;
    let filtered = [];
    if (value.toLowerCase() === "x") {
      filtered = initialHearAboutUsList?.filter((i) => i === "𝕏");
    } else {
      filtered = initialHearAboutUsList?.filter((i) =>
        i?.value?.toLowerCase().includes(value.toLowerCase())
      );
    }
    setHearAboutList(filtered);
  };

  const getFirstErrorFieldRef = (errors) => {
    if (errors.fname || errors.fname2) {
      return fnameRef;
    }
    if (errors.lname || errors.lname2) {
      return lnameRef;
    }
    if (errors.email) {
      return mailRef;
    }
    if (errors.number) {
      return numberRef;
    }
    if (errors.location) {
      return locationMainRef;
    }
    if (errors.natureOfEnquiry) {
      return natureOfEnquiryMainRef;
    }
    if (errors.hearAboutUs) {
      return hearAboutUsMainRef;
    }
    if (errors.addAMessage) {
      return messageRef;
    }
    if (errors.terms) {
      return termsRef;
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      setIsSending(true);
      setIsSubmitClicked(false);

      const submissionData = {
        email: formData.email,
        hear: formData.hearAboutUs,
        location: formData.location,
        message: formData.addAMessage,
        fname: formData.fname,
        lname: formData.lname,
        natureOfEnquiry: initialNatureOfEnquiryList.find(
          (item) => item.value === formData.natureOfEnquiry
        ),
        phone: formData.number || "",
        captchaToken: captchaToken,
      };

      // console.log("Submitted Data:", submissionData);

      submitContactFormDetails(submissionData)
        .then((res) => {
          if (res.code === "success") {
            // setSuccessMessage(res.message);

            toast(contactForm_successMsgText, {
              className: "gform_confirmation_message",
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              // transition: Bounce,
            });
            setFormData(formObj);
            setTimeout(() => {
              setIsSending(false);
            }, 500);
            setErrorMessage("");

            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
              setCaptchaToken(null);
            }

            setShowcheckTick(false);
            // setTimeout(() => {
            //   setSuccessMessage("");
            // }, 4000);
          } else if (res.error === "Invalid CAPTCHA") {
            setErrorMessage("Invalid CAPTCHA. Please try again.");
            setIsSending(false);
          }
        })
        .catch((error) => {
          setErrorMessage("Please enter CAPTCHA");
          setIsSending(false);
        });
    } else {
      setErrors(validationErrors);
      setIsSubmitClicked(true);
      const errorFieldRef = getFirstErrorFieldRef(validationErrors);
      if (errorFieldRef) {
        errorFieldRef?.current?.focus();
        errorFieldRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const handleCountryClick = (e) => {
    setFormData((data) => ({ ...data, location: e.target.innerText }));
    setErrors({ ...errors, location: "" });
    setShowLocationDropdown(false);
    setCountryList(countries);
    locationInputRef.current.value = "";
  };

  const handleNatureOfEnquiryClick = (e) => {
    setFormData((data) => ({ ...data, natureOfEnquiry: e.target.innerText }));
    setErrors({ ...errors, natureOfEnquiry: "" });
    setShowNatureOfEnquiryDropdown(false);
  };

  const handleHearAboutUsClick = (e) => {
    setFormData((data) => ({ ...data, hearAboutUs: e.target.innerText }));
    setErrors({ ...errors, hearAboutUs: "" });
    setShowHearAboutUsDropdown(false);
  };
  const handleOutSideClick = (e) => {
    if (
      locationMainRef.current &&
      !locationMainRef.current.contains(e.target)
    ) {
      setShowLocationDropdown(false);
    }

    if (
      natureOfEnquiryMainRef.current &&
      !natureOfEnquiryMainRef.current.contains(e.target)
    ) {
      setShowNatureOfEnquiryDropdown(false);
    }

    if (
      hearAboutUsMainRef.current &&
      !hearAboutUsMainRef.current.contains(e.target)
    ) {
      setShowHearAboutUsDropdown(false);
    }
  };

  const handleLength = (e) => {
    let iValue = e.target.value;
    if (iValue.length > 15) {
      iValue = iValue.slice(0, 15);
      e.target.value = iValue;
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutSideClick, false);
    return () => {
      document.removeEventListener("click", handleOutSideClick, false);
    };
  }, [locationMainRef, hearAboutUsMainRef, natureOfEnquiryMainRef]);

  const callContactPageData = async (language) => {
    try {
      let data = await getContactPageData(language);
      document.title = data?.contactData?.contactSEOTitle;
      setContactPageData(data);
      setAnimatedHeaderText(data?.contactData?.animatedHeaderText);
      setInitialHearAboutUsList(
        data?.contactData
          ?.contactForm_whereDidYouHearAboutUs_whereDidYouHearAboutUsValues
      );
      setHearAboutList(
        data?.contactData
          ?.contactForm_whereDidYouHearAboutUs_whereDidYouHearAboutUsValues
      );
      setInitialNatureOfEnquiryList(
        data?.contactData?.contactForm_natureOfEnquiry_natureOfEnquiryValues
      );
      setNatureOfEnquiryList(
        data?.contactData?.contactForm_natureOfEnquiry_natureOfEnquiryValues
      );
      setLocations(
        data?.locations.sort((a, b) => {
          return a.locationOrder - b.locationOrder;
        })
      );

      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (animatedHeaderText.length > 0) {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % animatedHeaderText.length);
      }, 1500);

      return () => clearInterval(interval);
    }
  }, [animatedHeaderText.length]);

  if (!contactPageData) {
    return <Loader />;
  }
  const { contactData } = contactPageData;
  const handleCaptchaChange = (token) => {
    if (token !== null) {
      setErrorMessage("");
    }
    setCaptchaToken(token);
  };

  const {
    bannerSection_contactText,
    bannerSection_helloText,
    contactForm_firstName,
    contactForm_lastName,
    contactForm_email,
    contactForm_whereDidYouHearAboutUs_whereDidYouHearAboutUsText,
    contactForm_location,
    contactForm_byTickingThisBoxCheckbox,
    contactForm_addAMessage,
    contactForm_natureOfEnquiry_natureOfEnquiryText,
    contactForm_sendButton,
    contactForm_telephoneText,
    contactForm_validationMessages_addAMessageValidationMessage,
    contactForm_validationMessages_firstNameValidationMessage,
    contactForm_validationMessages_specialCharactersAndNumbersValidationMessageForFirstName,
    contactForm_validationMessages_lastNameValidationMessage,
    contactForm_validationMessages_specialCharactersAndNumbersValidationMessageForLastName,
    contactForm_validationMessages_emailValidationMessage,
    contactForm_validationMessages_contactNumberValidationMessage,
    contactForm_validationMessages_natureOfEnquiry,
    contactForm_validationMessages_locationValidationMessage,
    contactForm_validationMessages_privacyPolicyValidationMessage,
    contactForm_privacyPolicyText,
    contactForm_privacyPolicyLink,
    bannerSection_bannerBgImage,
    bannerSection_bannerBgImageTab,
    bannerSection_bannerBgImageMob,
    contactForm_successMsgText,
  } = contactData;
  return (
    <>
      <main>
        <section className="bg-[#2AD9FF]  flex pt-28 pb-28 sm:pb-16 lg:pb-12 mb-16 lg:mb-28 slant-bottom-footerk relative z-0">
          <div className="hidden lg:block absolute top-0 right-0 z-[-1] w-[65%] xl:w-[70%] 3xl:w-[65%] h-full">
            <img
              src={bannerSection_bannerBgImage}
              alt="side-ptw"
              className="h-full w-full float-right"
            />
          </div>
          <div className="hidden sm:block lg:hidden h-full absolute top-0 right-0">
            <img
              src={bannerSection_bannerBgImageTab}
              alt="banner-img_tab"
              className=" h-full w-full "
            />
          </div>
          <div className="block sm:hidden w-full absolute bottom-0">
            <img
              className={"h-full w-full"}
              alt={"banner_img_mob"}
              src={bannerSection_bannerBgImageMob}
            />
          </div>
          <div className="absolute right-0 -bottom-[1px] z-[10]">
            <SlantImg className="h-[95px] sm:h-[110px] lg:h-auto" />
          </div>
          {/* <TopBannerForOtherPages pageName="Contact" /> */}
          <div className="container lg:flex justify-between items-center">
            <div className="uppercase text-[#004780] mb-12">
              <h3 className="text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px]">
                {bannerSection_contactText}
              </h3>
              <h2
                className={`text-projects-title text-[40px] lg:text-[53.5px] xl:text-[64px] 2xl:text-[76px]  3xl:text-[96px] 4xl:text-[115px] leading-[100%] sm:leading-[90%] -tracking-[1.3px] font-dela-gothic`}
                style={{
                  fontWeight:
                    animatedHeaderText[index]?.helloAnimationText ===
                    "안녕하세요"
                      ? 800
                      : 400,
                }}
              >
                {/* {bannerSection_helloText} */}
                {animatedHeaderText[index]?.helloAnimationText}
              </h2>
            </div>
            <form
              className="bg-[#004780cc] p-8 backdrop-blur-sm rounded-[8px] flex flex-col gap-3 w-full lg:w-[60%]"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-y-0 justify-between">
                {/* ---------------first name -----------------*/}
                <div className="relative  w-full sm:w-[48%]">
                  <label
                    className={`text-[#EFEFEF] absolute top-0 left-0  pl-4 py-3  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] pointer-events-none h-6 transition-all duration-300 ease-in-out ${
                      focusStates[0] || formData.fname
                        ? "!-top-[9px] xs:!-top-[10px]  left-4 bg-[#086499]  !px-[4px] !py-0 rounded-md !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px] z-[2]"
                        : ""
                    }`}
                  >
                    {contactForm_firstName}
                    <svg
                      className="inline ml-[1px] -translate-y-[1px]"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                        fill="#EFEFEF"
                      />
                    </svg>
                  </label>

                  <input
                    className="text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px] text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]"
                    ref={fnameRef}
                    type="text"
                    name="fname"
                    onChange={(e) => {
                      const maxLength = 30;
                      if (e.target.value.length > maxLength) {
                        e.target.value = e.target.value.slice(0, maxLength);
                      }
                      handleChange(e);
                      if (isSubmitClicked) handleBlur(e);
                    }}
                    value={formData.fname}
                    maxLength={30} // Keep maxLength for other devices
                    onBlur={(e) => handleBlur(e, 0)}
                    onFocus={() => handleFocus(0)}
                    tabIndex={1}
                  />

                  {(errors.fname || errors.fname2) && (
                    <p className="text-[#ff557e]  leading-[1.2]">
                      {errors.fname
                        ? contactForm_validationMessages_firstNameValidationMessage
                        : contactForm_validationMessages_specialCharactersAndNumbersValidationMessageForFirstName}
                    </p>
                  )}
                </div>
                {/*----------- last name ----------------*/}
                <div className="relative  w-full sm:w-[48%]">
                  <label
                    className={`text-[#EFEFEF] absolute top-0 left-0  pl-4 py-3  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]  pointer-events-none h-6 transition-all duration-300 ease-in-out ${
                      focusStates[1] || formData.lname
                        ? "!-top-[9px] xs:!-top-[10px] left-4 bg-[#086499] !px-[4px] !py-0 rounded-md !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px] "
                        : ""
                    }`}
                  >
                    {contactForm_lastName}
                    <svg
                      className="inline ml-[1px] -translate-y-[1px]"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                        fill="#EFEFEF"
                      />
                    </svg>
                  </label>
                  <input
                    className="text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] "
                    ref={lnameRef}
                    type="text"
                    name="lname"
                    onChange={(e) => {
                      const maxLength = 30;
                      if (e.target.value.length > maxLength) {
                        e.target.value = e.target.value.slice(0, maxLength);
                      }
                      handleChange(e);
                      if (isSubmitClicked) handleBlur(e);
                    }}
                    value={formData.lname}
                    maxLength={30}
                    onBlur={(e) => handleBlur(e, 1)}
                    onFocus={() => {
                      handleFocus(1);
                    }}
                    tabIndex={2}
                  />
                  {(errors.lname || errors.lname2) && (
                    <p className="text-[#ff557e]  leading-[1.2] mt-1">
                      {errors.lname
                        ? contactForm_validationMessages_lastNameValidationMessage
                        : contactForm_validationMessages_specialCharactersAndNumbersValidationMessageForLastName}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-y-0 justify-between">
                {/*--------- email ----------------*/}
                <div className="relative  w-full sm:w-[48%]">
                  <label
                    className={`text-[#EFEFEF] absolute top-0 left-0  pl-4 py-3  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]  pointer-events-none h-6 transition-all duration-300 ease-in-out ${
                      focusStates[2] || formData.email
                        ? "!-top-[9px] xs:!-top-[10px] left-4 bg-[#086499] !px-[4px] !py-0 rounded-md !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px] "
                        : ""
                    }`}
                  >
                    {contactForm_email}
                    <svg
                      className="inline ml-[1px] -translate-y-[1px]"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                        fill="#EFEFEF"
                      />
                    </svg>
                  </label>
                  <input
                    className="text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] "
                    ref={mailRef}
                    type="text"
                    name="email"
                    onChange={(e) => {
                      handleChange(e);
                      if (isSubmitClicked) handleBlur(e);
                    }}
                    value={formData.email}
                    maxLength={30}
                    onBlur={(e) => handleBlur(e, 2)}
                    onFocus={() => {
                      handleFocus(2);
                    }}
                    tabIndex={3}
                  ></input>
                  {errors.email && (
                    <p className="mt-1 text-[#ff557e]  leading-[1.2]">
                      {contactForm_validationMessages_emailValidationMessage}
                    </p>
                  )}
                </div>
                {/*--------- Telephone ----------------*/}

                <div className="relative  w-full sm:w-[48%]">
                  <label
                    className={`text-[#EFEFEF] absolute top-0 left-0  pl-4 py-3  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]  pointer-events-none h-6 transition-all duration-300 ease-in-out ${
                      focusStates[3] || formData.number
                        ? "!-top-[9px] xs:!-top-[10px] left-4 bg-[#086499] !px-[4px] !py-0 rounded-md !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px] "
                        : ""
                    }`}
                  >
                    {contactForm_telephoneText}
                  </label>
                  <input
                    className="text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] "
                    ref={numberRef}
                    type="text"
                    name="number"
                    onChange={(e) => {
                      if (e.target.value.length <= 15) {
                        handleChange(e);
                        if (isSubmitClicked) handleBlur(e);
                      }
                    }}
                    value={formData.number}
                    onBlur={(e) => handleBlur(e, 3)}
                    onFocus={() => {
                      handleFocus(3);
                    }}
                    tabIndex={4}
                  />
                  <div>
                    {errors.number && (
                      <p className="mt-1 text-[#ff557e]  leading-[1.2]">
                        {
                          contactForm_validationMessages_contactNumberValidationMessage
                        }
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/*--------- where did you hear about us ----------------*/}
              <div
                className="relative y-arrow w-full"
                onClick={() => {
                  setShowHearAboutUsDropdown(!showHearAboutUsDropdown);
                  if (!showHearAboutUsDropdown) {
                    setHearAboutList(initialHearAboutUsList);
                    setTimeout(() => {
                      hearAboutUsInputRef?.current?.focus();
                    }, 200);
                  }
                }}
                ref={hearAboutUsMainRef}
              >
                <label
                  className={`cursor-pointer  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] ${
                    showHearAboutUsDropdown ? "opacity-0" : "opacity-100"
                  }`}
                  tabIndex={5}
                >
                  <div id="hear-dropdown" className="relative cursor-pointer">
                    <span
                      className={` absolute text-[#fff] ${
                        getLanguage() === "fr-ca" && !formData?.hearAboutUs
                          ? "top-[-1px]"
                          : "top-[14px]"
                      }  ml-4`}
                    >
                      {formData?.hearAboutUs
                        ? formData?.hearAboutUs
                        : contactForm_whereDidYouHearAboutUs_whereDidYouHearAboutUsText}
                    </span>
                    {formData?.hearAboutUs !== "" && (
                      <span className="absolute text-[#fff] !-top-[9px] xs:!-top-[10px] ml-4 bg-[#086499] z-[2] !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px]">
                        {
                          contactForm_whereDidYouHearAboutUs_whereDidYouHearAboutUsText
                        }
                      </span>
                    )}
                    <div
                      type="text"
                      className="flex justify-end items-center text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] h-[48px] md:h-[52px] 3xl:h-[56px] 4xl:h-[60px]"
                    >
                      <img
                        alt="side-ptw"
                        src="/images/ArrowDown.svg"
                        className="mr-2 text-right h-[20px]"
                      />
                    </div>
                  </div>
                </label>

                {showHearAboutUsDropdown ? (
                  <div className="absolute top-0 bg-[#086499]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] cursor-pointer w-full z-10 text-white rounded-[4px]">
                    <span className=" absolute text-[#fff] !-top-[9px] xs:!-top-[10px] ml-4 bg-[#086499] z-[2] !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px]">
                      {
                        contactForm_whereDidYouHearAboutUs_whereDidYouHearAboutUsText
                      }
                    </span>

                    <input
                      type="search"
                      placeholder="Search"
                      id="hearAboutUs"
                      maxLength="30"
                      className="appearance-none w-[100%] px-[1rem] sticky cursor-unset bg-transparent focus-visible:outline-none py-3 border rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]"
                      autoComplete="off"
                      ref={hearAboutUsInputRef}
                      onChange={handleHearAboutSearch}
                    />
                    <img
                      alt="side-ptw"
                      src="/images/ArrowDown.svg"
                      className="mr-2 text-right h-[20px] absolute right-0 top-4 rotate-180"
                    />
                    <div
                      id="dropdownList"
                      className="dropdown-list bg-transparent h-[10rem] overflow-auto scrollable border border-t-0 border-[#ffffff] custom-scroll rounded-[4px]"
                    >
                      {hearAboutList
                        ?.sort((a, b) => a.value.localeCompare(b.value))
                        .map((item, index) => (
                          <p
                            key={index}
                            className="dropdown-item py-[.4rem] px-[1rem]  "
                            onClick={handleHearAboutUsClick}
                          >
                            {item.value}
                          </p>
                        ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/*   <div>
             {errors.hearAboutUs && (
                  <p className="pt-1 text-[#ff557e]  leading-[1.2]">
                    {
                      // contactPageData?.
                      "Validation Message need to Pass"
                    }
                  </p>
                )} 
              </div>*/}
              </div>

              <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-y-0 justify-between">
                {/*--------- location   ----------------*/}
                <div
                  className="relative y-arrow w-full sm:w-[48%]"
                  ref={locationMainRef}
                  onClick={() => {
                    if (!showLocationDropdown) {
                      setShowLocationDropdown(!showLocationDropdown);
                      setCountryList(countries); // Ensure the full list is shown initially
                      setTimeout(() => {
                        locationInputRef?.current?.focus();
                      }, 200);
                    }
                  }}
                >
                  <label
                    className={`cursor-pointer  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] ${
                      showLocationDropdown ? "opacity-0" : "opacity-100"
                    }`}
                    tabIndex={6}
                  >
                    <div className="relative cursor-pointer">
                      <span className="absolute  text-[#fff] top-[14px] ml-4">
                        {formData.location
                          ? formData.location
                          : contactForm_location}
                        {!formData.location && (
                          <svg
                            className="inline ml-[1px] -translate-y-[1px]"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                              fill="#EFEFEF"
                            />
                          </svg>
                        )}
                      </span>
                      {formData?.location && (
                        <span className=" absolute text-[#fff] !-top-[9px] xs:!-top-[10px] ml-4 bg-[#086499] z-[2] !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px]">
                          {contactForm_location}
                          <svg
                            className="inline ml-[1px] -translate-y-[1px]"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                              fill="#EFEFEF"
                            />
                          </svg>
                        </span>
                      )}
                      <div
                        type="text"
                        className="flex justify-end items-center text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]  h-[48px] md:h-[52px] 3xl:h-[56px] 4xl:h-[60px]"
                      >
                        <img
                          alt="side-ptw"
                          src="/images/ArrowDown.svg"
                          className="mr-2 h-[20px]"
                        />
                      </div>
                    </div>
                  </label>

                  {showLocationDropdown ? (
                    <div
                      className={`absolute top-0 bg-[#086499]  cursor-pointer w-full z-10 text-white rounded-[4px]`}
                      id="location"
                    >
                      <span className=" absolute text-[#fff] !-top-[9px] xs:!-top-[10px] ml-4 bg-[#086499] z-[2] !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px]">
                        {contactForm_location}
                        <svg
                          className="inline ml-[1px] -translate-y-[1px]"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                            fill="#EFEFEF"
                          />
                        </svg>
                      </span>

                      <input
                        type="search"
                        placeholder="Search"
                        id="location"
                        maxLength="30"
                        className="appearance-none w-[100%] px-[1rem] sticky cursor-unset bg-transparent focus-visible:outline-none py-3 border rounded-[4px] text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]"
                        autoComplete="off"
                        ref={locationInputRef}
                        onChange={handleLocationSearch}
                      />
                      <img
                        alt="side-ptw"
                        src="/images/ArrowDown.svg"
                        className="mr-2 text-right h-[20px] absolute right-0 top-4 rotate-180"
                      />
                      <div
                        id="dropdownList"
                        className="dropdown-list bg-transparent h-[10rem] overflow-auto scrollable border border-t-0 border-[#ffffff] custom-scroll rounded-[4px]"
                      >
                        {countryList?.map((country) => (
                          <p
                            key={country.name}
                            className="dropdown-item py-[.4rem] px-[1rem] "
                            onClick={handleCountryClick}
                          >
                            {country.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {errors.location && (
                    <p className="pt-1 text-[#ff557e]  leading-[1.2]">
                      {contactForm_validationMessages_locationValidationMessage}
                    </p>
                  )}
                </div>

                {/*--------- nature of enquiry  ----------------*/}
                <div
                  className="relative y-arrow  w-full sm:w-[48%]"
                  ref={natureOfEnquiryMainRef}
                  onClick={() => {
                    setShowNatureOfEnquiryDropdown(
                      !showNatureOfEnquiryDropdown
                    );
                    if (!showNatureOfEnquiryDropdown) {
                      setNatureOfEnquiryList(initialNatureOfEnquiryList);
                      setTimeout(() => {
                        natureOfEnquiryInputRef?.current?.focus();
                      }, 200);
                    }
                  }}
                >
                  <label
                    className={`cursor-pointer  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] ${
                      showNatureOfEnquiryDropdown ? "opacity-0" : "opacity-100"
                    }`}
                    tabIndex={7}
                  >
                    <div id="hear-dropdown" className="relative">
                      <span className="absolute  text-[#ffffff] top-[14px] ml-4">
                        {formData?.natureOfEnquiry
                          ? formData?.natureOfEnquiry
                          : contactForm_natureOfEnquiry_natureOfEnquiryText}

                        {!formData?.natureOfEnquiry && (
                          <svg
                            className="inline ml-[1px] -translate-y-[1px]"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                              fill="#EFEFEF"
                            />
                          </svg>
                        )}
                      </span>
                      {formData?.natureOfEnquiry && (
                        <span className=" absolute text-[#ffffff] !-top-[9px] xs:!-top-[10px] ml-4 bg-[#086499] z-[2] !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px]">
                          {contactForm_natureOfEnquiry_natureOfEnquiryText}
                          <svg
                            className="inline ml-[1px] -translate-y-[1px]"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                              fill="#EFEFEF"
                            />
                          </svg>
                        </span>
                      )}
                      <div
                        type="text"
                        className="flex justify-end items-center text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]  h-[48px] md:h-[52px] 3xl:h-[56px] 4xl:h-[60px]"
                      >
                        <img
                          alt="side-ptw"
                          src="/images/ArrowDown.svg"
                          className="mr-2 h-[20px]"
                        />
                      </div>
                    </div>
                  </label>

                  {showNatureOfEnquiryDropdown ? (
                    <div className="absolute top-0 bg-[#086499]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] cursor-pointer w-full z-10 text-white rounded-[4px]">
                      <span className=" absolute text-[#fff] !-top-[9px] xs:!-top-[10px] ml-4 bg-[#086499] z-[2] !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px]">
                        {contactForm_natureOfEnquiry_natureOfEnquiryText}
                        <svg
                          className="inline ml-[1px] -translate-y-[1px]"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                            fill="#EFEFEF"
                          />
                        </svg>
                      </span>
                      <input
                        type="search"
                        placeholder="Search"
                        id="natureOfEnquiry"
                        maxlength="30"
                        className="appearance-none w-[100%] px-[1rem] sticky cursor-unset bg-transparent focus-visible:outline-none py-3 border rounded-[4px]"
                        autocomplete="off"
                        ref={natureOfEnquiryInputRef}
                        onChange={handleNatureOfEnquirySearch}
                      />

                      <img
                        alt="side-ptw"
                        src="/images/ArrowDown.svg"
                        className="mr-2 text-right h-[20px] absolute right-0 top-4 rotate-180"
                      />
                      <div
                        id="dropdownList"
                        className="dropdown-list bg-transparent h-[7rem] 2xl:h-[8.5rem] overflow-auto scrollable border border-t-0 border-[#ffffff] custom-scroll rounded-[4px]"
                      >
                        {natureOfEnquiryList
                          ?.sort((a, b) => a.value.localeCompare(b.value))
                          .map((item, index) => (
                            <p
                              key={index}
                              className="dropdown-item py-[.4rem] px-[1rem] "
                              onClick={handleNatureOfEnquiryClick}
                            >
                              {item.value}
                            </p>
                          ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    {errors.natureOfEnquiry && (
                      <p className="pt-1 text-[#ff557e]  leading-[1.2]">
                        {contactForm_validationMessages_natureOfEnquiry}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/*--------- Message textarea  ----------------*/}

              <div className="relative">
                <label
                  className={`text-[#EFEFEF] absolute top-0 left-0  pl-4 py-3  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]  pointer-events-none h-6 transition-all duration-300 ease-in-out ${
                    focusStates[4] || formData.addAMessage
                      ? "!-top-[9px] xs:!-top-[10px] left-4 bg-[#086499]  !px-[4px] !py-0 rounded-md !text-[12px] 2xl:!text-[14px] 3xl:!text-[16px] 4xl:!text-[18px]   z-[2]"
                      : ""
                  }`}
                >
                  {contactForm_addAMessage}
                  <svg
                    className="inline ml-[1px] -translate-y-[1px]"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.83636 0L4.49091 3.41333L7.76364 2.45333L8 4.08889L4.87273 4.35556L6.90909 7.16444L5.4 8L3.96364 4.88889L2.65455 8L1.10909 7.16444L3.09091 4.35556L0 4.08889L0.254545 2.45333L3.47273 3.41333L3.12727 0H4.83636Z"
                      fill="#EFEFEF"
                    />
                  </svg>
                </label>
                <textarea
                  className="text-white border-white border pl-4 py-3 bg-transparent focus:outline-none w-full rounded-[4px]  text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] h-[7rem] 3xl:h-[10rem] placeholder-white"
                  ref={messageRef}
                  name="addAMessage"
                  value={formData.addAMessage}
                  onChange={(e) => {
                    handleChange(e);
                    if (isSubmitClicked) handleBlur(e);
                  }}
                  onBlur={(e) => handleBlur(e, 4)}
                  onFocus={() => {
                    handleFocus(4);
                  }}
                  maxLength={5000}
                  tabIndex={8}
                ></textarea>
                {errors.addAMessage && (
                  <p className="pt-1 text-[#ff557e]  leading-[1.2]">
                    {
                      contactForm_validationMessages_addAMessageValidationMessage
                    }
                  </p>
                )}
              </div>
              {/*--------- Checkbox  ----------------*/}
              <div className="contact-checkbox relative">
                <label
                  className="block lg:flex justify-start items-center  text-xs 2xl:text-sm 3xl:text-[1.1rem] 4xl:text-xl break-all !leading-normal"
                  tabIndex="9"
                >
                  <input
                    type="checkbox"
                    name="terms"
                    className="w-6 sm:w-6 lg:w-8 xl:w-8 2xl:w-8 h-6 xl:h-8 2xl:h-8 3xl:w-[2.6rem] 3xl:h-10 mr-2 appearance-none text-white border-[2px] border-white border-solid rounded cursor-pointer float-left mt-[5px] lg:mt-0"
                    checked={formData.terms}
                    onChange={handleTermsChange}
                  />
                  {showCheckTick && (
                    <span className="text-white p-[5px] absolute leading-[26px] lg:leading-[19px] cursor-pointer lg:left-auto left-0 xl:left-[1px] 2xl:left-[2.5px] 3xl:left-[0.3rem] text-[18px] xl:text-[22px] 2xl:text-2xl">
                      ✓
                    </span>
                  )}
                  <span className="table break-keep text-white !leading-normal">
                    {contactForm_byTickingThisBoxCheckbox}
                    <Link
                      to={contactForm_privacyPolicyLink}
                      rel="noopener noreferrer"
                      className="text-[#FFF647] ml-1"
                    >
                      {contactForm_privacyPolicyText}.
                    </Link>
                  </span>
                </label>
                <div className="h-4">
                  {errors.terms && (
                    <p className="text-[#ff557e]  leading-[1.2] ">
                      {errors.terms}
                    </p>
                  )}
                </div>
                <div className="lg:w-[25rem] h-1 py-3"></div>
              </div>

              <div className="sm:flex justify-between items-center">
                <div className="w-[40%]">
                  <ReCAPTCHA
                    key={captchaKey}
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_KEY}
                    // sitekey="6Le6YeIqAAAAAAPKez4VjpqlpNxfAEtl4CTXBluq"
                    onChange={handleCaptchaChange}
                    hl={captchaLanguage}
                  />
                  {/* <Recaptcha
                    lang={lang}
                    onCaptchaChange={handleCaptchaChange}
                  /> */}
                </div>

                <ButtonSlant
                  buttonClass={`px-6  text-[#1A1A1A] text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] rounded-[3px] uppercase font-dela h-12 w-full mt-8 lg:mt-0 ${
                    isSending
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-[#FFF647]"
                  }`}
                  slantClass="bottom-right-slant"
                  buttonText={contactForm_sendButton}
                  disabled={isSending}
                  divClass="w-full sm:w-fit 2xl:w-[40%]"
                  tabIndex="10"
                />
              </div>
              <div className="text-center">
                {/* {successMessage && (
                  <div
                    style={{ color: "white" }}
                    className="text-white text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] "
                  >
                    {successMessage}
                  </div>
                )} */}
                {errorMessage && (
                  <div className="text-[#ff557e] text-[16px] 2xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px] ">
                    {errorMessage}
                  </div>
                )}
              </div>
            </form>
          </div>
        </section>
        <div className="bg-d relative ">
          <img
            src={contactData?.accordionBgImage}
            alt="ptw-side"
            className="absolute bottom-0 right-0 hidden w-[34%] lg:block -z-[2]"
          />
          <img
            src={contactData?.accordionBgImageTab}
            alt="ptw-side"
            className="absolute bottom-[70rem] sm:bottom-[6rem]  w-[45%] sm:w-[40%] right-0 block lg:hidden -z-[2]"
          />
          <ContactAccrordion contactData={contactData} locations={locations} />

          <Footer />
        </div>
      </main>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        // transition={Bounce}
      />
    </>
  );
};

export default Contact;
